/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.cart-modal {
  ion-toolbar{
    --background: #3b3b3b;
  }
  //--height: 100%;
  //--border-radius: 4px;
  //--border-top: #ff8000 2px solid;
  /*padding-top: 15vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh;*/
  //font-family: "IBM-Bold";
}
.location-modal {
  ion-toolbar{
    --background: #3b3b3b;
  }
  //--height: 100%;
  //--border-radius: 4px;
  //--border-top: #ff8000 2px solid;
  padding-top: 8vh;
  /* padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh; */
  //font-family: "IBM-Bold";
}
.menu-modal {
  //--height: 100%;
  //--width: 100%;
  //--border-radius: 20px;
  --border-top: orange 2px solid;
  padding-top: 15vh;
  --border-radius: 4px;
}
.establecimiento-modal {
  //padding-top: 7vh;
  --border-radius: 4px;
}
.detalle-orden-modal {
  --border-radius: 10px;
  --height: 85%;
  --width: 85%;
  //padding: 15px;
}